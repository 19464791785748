<template>
  <!-- 页面整体布局容器-->
  <div>
    <!-- <div class="side-menu">
      <div class="item">
        <a class="side-eamil" href="mailto:sale@richbeam.com"
          ><img
            class="side-icon"
            src="@/assets/images/zixun.png"
            alt="Product Consultation"
          />
          <p class="text">Product</p>
        </a>
      </div>
      <div class="item">
        <a class="side-eamil" href="mailto:pr@richbeam.com"
          ><img
            class="side-icon"
            src="@/assets/images/support.png"
            alt="technical advice"
          />
          <p class="text">Technical</p>
        </a>
      </div>
    </div> -->
    <div class="side-menu">
      <div class="menu-li">
        <div class="form-li">
          <span class="form-text">Form Submit</span>
          <img
            class="side-icon"
            src="@/assets/images/form.png"
            alt="Product Consultation"
          />
        </div>
        <div class="element-contact-form">
          <div style="padding: 10px 15px">
            <h3 class="h3">Form Submit</h3>
            <IndexSubmit></IndexSubmit>
          </div>
        </div>
      </div>
      <div class="menu-li">
        <span class="social-custom"
          ><a
            href="https://api.whatsapp.com/send?phone=8618511586482&text=Brand%20and%20model%EF%BC%9A"
            target="_blank"
            >WhatsApp</a
          ></span
        >
        <span class="social-text">
          <a
            class="social-a"
            href="https://api.whatsapp.com/send?phone=8618511586482&text=Brand%20and%20model%EF%BC%9A"
            target="_blank"
          >
            <img
              class="side-icon"
              src="@/assets/images/whatsapp.png"
              alt="Product Consultation"
            />
            <span class="text">WhatsApp</span></a
          ></span
        >
      </div>
      <div class="menu-li">
        <span class="social-custom"><a href="mailto:sale@richbeam.com">Email</a></span>
        <span class="social-text">
          <a class="social-a" href="mailto:sale@richbeam.com">
            <img
              class="side-icon"
              src="@/assets/images/email.png"
              alt="Product Consultation"
            />
            <span class="text">Email</span></a
          ></span
        >
      </div>
    </div>
    <header
      class="app-header"
      @mouseenter="headEnter"
      @mouseleave="headOuter"
      :class="{ activeOne: headActive == true || asideNav == true }"
    >
      <RouterLink class="app-logo" to="/en">
        <img
          class="img"
          :src="headActive || asideNav ? logoImgs.logoBlue : logoImgs.logoWhite"
          alt="RichBeam"
        />
      </RouterLink>
      <div class="app-header-nav">
        <RouterLink exact-active-class="nav-active" class="item" to="/en">
          <div class="bag">Home</div>
        </RouterLink>
        <div class="item" @mouseenter="handleEnter" @mouseleave="handleLeave">
          <div class="bag">Products</div>
        </div>
        <div class="item indu-box" @mouseenter="industryShow" @mouseleave="industryHide">
          <div class="bag">Applications</div>
          <div v-show="industryBox" class="industry-bag">
            <RouterLink
              exact-active-class="nav-active"
              class="odd"
              :to="'/en/industry/' + item?.lydz ?? ''"
              v-for="item in induList"
              :key="item._id"
              >{{ item.name }}</RouterLink
            >
          </div>
        </div>
        <div class="item" @mouseenter="solutionEnter" @mouseleave="solutionLeave">
          <div class="bag">LiDAR Solutions</div>
        </div>
        <RouterLink exact-active-class="nav-active" class="item" to="/en/download">
          <div class="bag">Support</div>
        </RouterLink>
        <div class="item about-box" @mouseenter="aboutShow" @mouseleave="aboutHide">
          <div class="bag">About Us</div>
          <div v-show="aboutBox" class="about-bag">
            <RouterLink exact-active-class="nav-active" class="odd" to="/en/about"
              >Company</RouterLink
            >
            <RouterLink exact-active-class="nav-active" class="odd" to="/en/contact"
              >Contact</RouterLink
            >
            <!-- <a
              class="odd"
              target="_blank"
              :href="footInfo ? footInfo[0].zpdz : ''"
              aria-label="Redirect to a third-party recruitment address"
              >Join Us</a
            > -->
          </div>
        </div>
      </div>
      <div class="app-header-right">
        <div v-show="!asideNav" class="app-switch" @click="changeLang">
          <span>{{ language[langIndex].content }}</span>
          <img
            class="black-down"
            v-show="headActive"
            src="@/assets/images/arrow2.png"
            alt="arrow"
          />
          <img
            class="white-down"
            v-show="!headActive"
            src="@/assets/images/arrow.png"
            alt="arrow"
          />
          <div class="dropdown" v-show="langShow">
            <span
              @click.stop="selectLang(index)"
              :class="langIndex == index ? 'active' : ''"
              v-for="(item, index) in language"
              :key="item.id"
              :data-index="index"
              >{{ item.content }}</span
            >
          </div>
        </div>
        <div v-if="asideNav" class="aside-nav" @click="bindAside">
          <img class="colse" src="@/assets/images/close.png" alt="close" />
        </div>
        <div v-else class="aside-nav" @click="bindAside">
          <img
            v-show="!headActive && !asideNav"
            class="img white-img"
            src="@/assets/images/slideNav.png"
            alt="slide"
          />
          <img
            v-show="headActive || asideNav"
            class="img black-img"
            src="@/assets/images/slideNav2.png"
            alt="slide"
          />
        </div>
      </div>
      <!-- 产品下拉列表 -->
      <div
        class="app-protypes"
        @mouseenter="handleEnter"
        @mouseleave="handleLeave"
        v-show="procenter"
      >
        <div class="inner">
          <div class="pro-cell">
            <p>Industrial-Grade 2D LiDAR</p>
            <div class="types">
              <Router-link
                :to="'/en/product/' + item?.lydz ?? ''"
                class="item"
                v-for="item in proSection"
                :key="item._id"
              >
                <img :src="item.img" :alt="item.name" />
                <span class="name">{{ item.name }}</span>
                <span v-if="item.new" class="tag">new</span>
              </Router-link>
            </div>
          </div>
          <div class="pro-cell">
            <p>Industrial-Grade 3D LiDAR</p>
            <div class="types">
              <Router-link
                class="item"
                v-for="item in dproSection"
                :key="item._id"
                :to="'/en/multiline/' + item?.lydz ?? ''"
              >
                <img :src="item.img" :alt="item.name" />
                <span class="name">{{ item.name }}</span>
                <span v-if="item.new" class="tag">new</span>
              </Router-link>
            </div>
          </div>
          <div class="pro-cell">
            <p>3D Scanning Module</p>
            <div class="types">
              <Router-link
                class="item"
                v-for="(item, index) in moduleSetion"
                :key="item?._id ?? index"
                :to="'/en/module/' + item?.lydz ?? ''"
              >
                <img :src="item.cpt" :alt="item.cpmc" />
                <span class="name">{{ item.cpmc }}</span>
                <span v-if="item.new" class="tag">new</span>
              </Router-link>
            </div>
          </div>
          <div class="pro-cell">
            <p>TOF Pure Solid-State LiDAR</p>
            <div class="types">
              <Router-link
                class="item"
                v-for="item in solidSection"
                :key="item._id"
                :to="'/en/solid/' + item?.lydz ?? ''"
              >
                <img :src="item.img" :alt="item.e_name" />
                <span class="name">{{ item.e_name }}</span>
                <span v-if="item.new" class="tag">new</span>
              </Router-link>
            </div>
          </div>
          <div class="pro-cell">
            <p>Event Camera</p>
            <div class="types">
              <Router-link
                class="item"
                v-for="item in cameraSection"
                :key="item._id"
                :to="'/en/camera/' + item?.lydz ?? ''"
              >
                <img :src="item.img" :alt="item.name" />
                <span class="name">{{ item.name }}</span>
                <span v-if="item.new" class="tag">new</span>
              </Router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案下拉列表 -->
      <div
        class="app-resolve"
        @mouseenter="solutionEnter"
        @mouseleave="solutionLeave"
        v-show="solcenter"
      >
        <div class="inner">
          <div class="pack" v-for="item in soluList" :key="item._id">
            <div class="primary">{{ item.name }}</div>
            <div class="second">
              <Router-Link
                exact-active-class="nav-active2"
                class="item"
                :to="'/en/solution/' + element?.lydz ?? ''"
                v-for="element in item.list"
                :key="element._id"
                >{{ element.name }}</Router-Link
              >
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- asideNav -->
    <div class="app-small-nav" v-show="asideNav" @scroll.prevent="asideScroll">
      <div class="item" @click="bindHome">Home</div>
      <div class="item" @click="bindMore(0)">
        <span>Products</span>
        <img
          :class="['img', proMore ? 'active' : '']"
          src="@/assets/images/arrow2.png"
          alt="arrow"
        />
      </div>
      <div class="nav-more" v-show="proMore">
        <div class="first">
          <p>Industrial-Grade 2D LiDAR</p>
          <div class="second">
            <span
              v-for="item in proSection"
              :key="item._id"
              @click="navigateToPage(1, item?.lydz ?? '')"
              >{{ item.name + " Series" }}</span
            >
          </div>
        </div>
        <div class="first">
          <p>Industrial-Grade 3D LiDAR</p>
          <div class="second">
            <span
              v-for="item in dproSection"
              :key="item._id"
              @click="navigateToPage(2, item?.lydz ?? '')"
              >{{ item.name + " Series" }}</span
            >
          </div>
        </div>
        <div class="first">
          <p>3D Scanning Module</p>
          <div class="second">
            <!-- <span @click="navigateToPage(5, 'module')">BeamMaster 1</span> -->
            <span
              v-for="item in moduleSetion"
              :key="item._id"
              @click="navigateToPage(5, item?.lydz ?? '')"
              >{{ item.cpmc }}</span
            >
          </div>
        </div>
        <div class="first">
          <p>TOF Pure Solid-State LiDAR</p>
          <div class="second">
            <span
              v-for="item in solidSection"
              :key="item._id"
              @click="navigateToPage(6, item?.lydz ?? '')"
              >{{ item.e_name }}</span
            >
          </div>
        </div>
        <div class="first">
          <p>Event Camera</p>
          <div class="second">
            <span
              v-for="item in cameraSection"
              :key="item._id"
              @click="navigateToPage(7, item?.lydz ?? '')"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
      <div class="item" @click="bindMore(1)">
        <span>Applications</span>
        <img
          :class="['img', induMore ? 'active' : '']"
          src="@/assets/images/arrow2.png"
          alt="arrow"
        />
      </div>
      <div class="nav-more" v-show="induMore">
        <span
          v-for="item in induList"
          :key="item._id"
          @click="navigateToPage(3, item?.lydz ?? '')"
          >{{ item.name }}</span
        >
      </div>
      <div class="item" @click="bindMore(2)">
        <span>LiDAR Solutions</span>
        <img
          :class="['img', soluMore ? 'active' : '']"
          src="@/assets/images/arrow2.png"
          alt="arrow"
        />
      </div>
      <div class="nav-more" v-show="soluMore">
        <div class="first" v-for="item in soluList" :key="item._id">
          <p>{{ item.name }}</p>
          <div class="second">
            <span
              v-for="element in item.list"
              :key="element._id"
              @click="navigateToPage(4, element?.lydz ?? '')"
              >{{ element.name }}</span
            >
          </div>
        </div>
      </div>
      <div class="item" @click="bindPage('download')">Support</div>
      <div class="item" @click="bindMore(3)">
        <span>About Us</span>
        <img
          :class="['img', aboutMore ? 'active' : '']"
          src="@/assets/images/arrow2.png"
          alt="arrow"
        />
      </div>
      <div class="nav-more" v-show="aboutMore">
        <span @click="bindPage('about')">Company</span>
        <span @click="bindPage('contact')">Contact</span>
        <!-- <span
          ><a
            :href="footInfo ? footInfo[0].zpdz : ''"
            target="_blank"
            aria-label="Redirect to a third-party recruitment address"
            >Join Us</a
          ></span
        > -->
      </div>
    </div>
    <div class="app-container">
      <!-- 二级路由出口 -->
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <!-- footer -->
    <div class="app-footer-box app-foot1">
      <div class="app-footer">
        <div class="foot-nav">
          <RouterLink class="app-logo" to="/en">
            <img class="img" src="@/assets/images/logoimg.png" alt="RichBeam" />
          </RouterLink>
          <div class="mid">
            <RouterLink class="item" to="/en">Home</RouterLink>
            <RouterLink class="item" :to="'/en/product/' + proSection?.[0]?.lydz ?? ''"
              >Products</RouterLink
            >
            <RouterLink class="item" :to="'/en/industry/' + induList?.[0]?.lydz ?? ''"
              >Applications</RouterLink
            >
            <RouterLink
              class="item"
              :to="'/en/solution/' + soluList?.[0]?.list?.[0]?.lydz ?? ''"
              >LiDAR Solutions</RouterLink
            >
            <RouterLink class="item" to="/en/download">Support</RouterLink>
            <RouterLink class="item" to="/en/about">About Us</RouterLink>
          </div>
          <div class="app-switch" @click="bindLang">
            <span>{{ language[langIndex].content }}</span>
            <img src="@/assets/images/arrow.png" alt="arrow" />
            <div class="dropdown" v-show="footLang">
              <span
                @click.stop="selectLang(index)"
                :class="langIndex == index ? 'active' : ''"
                v-for="(item, index) in language"
                :key="item.id"
                :data-index="index"
                >{{ item.content }}</span
              >
            </div>
          </div>
        </div>
        <div class="app-business">
          <div class="left">
            <div class="cell">
              <img src="@/assets/images/location.png" alt="address" />
              <span>Address:</span>
              <span>{{ footInfo?.[0]?.dz ?? "" }}</span>
            </div>
            <div class="cell">
              <img src="@/assets/images/web.png" alt="website" />
              <span>Website:</span>
              <span>{{ footInfo?.[0]?.gw ?? "" }}</span>
            </div>
            <div class="cell">
              <img src="@/assets/images/phone.png" alt="phone" />
              <span>Phone:</span>
              <span>{{ footInfo?.[0]?.phone ?? "" }}</span>
            </div>
            <div class="cell">
              <img src="@/assets/images/business.png" alt="commerce" />
              <span>Commerce:</span>
              <a class="mailto" :href="'mailto:' + footInfo?.[0]?.swyx ?? ''">{{
                footInfo?.[0]?.swyx ?? ""
              }}</a>
              <!-- <span>{{ footInfo[0].swyx }}</span> -->
            </div>
            <div class="cell">
              <img src="@/assets/images/market.png" alt="market" />
              <span>Market:</span>
              <a class="mailto" :href="'mailto:' + footInfo?.[0]?.scyx ?? ''">{{
                footInfo?.[0]?.scyx ?? ""
              }}</a>
              <!-- <span>{{ footInfo[0].scyx }}</span> -->
            </div>
            <div class="cell">
              <span>{{ footInfo?.[0]?.bq ?? "" }}</span>
            </div>
          </div>
          <div class="right">
            <a
              v-for="item in matrixList"
              :key="item._id"
              class="link"
              :href="item.link"
              target="_blank"
              aria-label="Jump to a third-party platform"
            >
              <img :src="item.dbtb" alt="matrix" />
            </a>
            <img
              class="wechat"
              :src="footInfo?.[0]?.wx ?? ''"
              alt="Richbeam Official WeChat"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 页面小于1136，底部区域 -->
    <footer class="app-footer-box app-foot2">
      <div class="app-footer">
        <div class="top">
          <div class="lt">
            <span>Follow RichBeam</span>
            <el-popover placement="top" width="200" trigger="hover">
              <img
                slot="reference"
                class="icon"
                src="@/assets/images/whatsapp.png"
                alt="wechat"
              />
              <!-- Whatsapp -->
              <div style="display: flex; flex-direction: column; align-items: center">
                <!-- <span style="font-size: 14px; font-weight: 600; color: #000"
                  >Follow RichBeam Information</span
                > -->
                <span style="font-size: 12px">@RichBeam Official Whatsapp</span>
                <img
                  style="width: 90px; height: 90px"
                  :src="footInfo?.[0]?.wx ?? ''"
                  alt="RichBeam Official WeChat"
                />
              </div>
            </el-popover>
            <a
              v-for="item in matrixList"
              :key="item._id"
              :href="item.link"
              target="_blank"
              aria-label="Jump to a third-party platform"
              ><img class="icon" :src="item.dbtb" alt="matrix"
            /></a>
          </div>
          <div class="app-switch" @click="bindLang">
            <span>{{ language[langIndex].content }}</span>
            <img src="@/assets/images/arrow.png" alt="arrow" />
            <div class="dropdown" v-show="footLang">
              <span
                @click.stop="selectLang(index)"
                :class="langIndex == index ? 'active' : ''"
                v-for="(item, index) in language"
                :key="item.id"
                :data-index="index"
                >{{ item.content }}</span
              >
            </div>
          </div>
        </div>
        <RouterLink class="app-logo" to="/en">
          <img class="img" src="@/assets/images/logoimg.png" alt="RichBeam" />
        </RouterLink>
        <div class="cell">
          <span>{{ footInfo?.[0]?.bq ?? "" }}</span>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getSeleData, seleData, getAllData } from "@/utils/http.js";
import IndexSubmit from "../../components/submit/IndexSubmit.vue";
export default {
  components: {
    IndexSubmit,
  },
  computed: {
    ...mapState([
      "eindexDanList",
      "eindexDuoList",
      "eindexModule",
      "eindexSolid",
      "eindexCamera",
      "eindexInduList",
      "eindexSoluList",
      "eindexFooter",
      "eindexMatrix",
    ]),
  },
  data() {
    return {
      logoImgs: {
        logoWhite: require("@/assets/images/logoimg2.png"),
        logoBlue: require("@/assets/images/logoimg.png"),
      },
      headActive: false,
      asideNav: false,
      langShow: false,
      footLang: false,
      language: [
        {
          id: 0,
          content: "CN",
        },
        {
          id: 1,
          content: "EN",
        },
      ],
      langIndex: 1,
      procenter: false,
      industryBox: false,
      aboutBox: false,
      aboutMore: false,
      induMore: false,
      proMore: false,
      solcenter: false,
      soluMore: false,
      proSection: [],
      dproSection: [],
      moduleSetion: [],
      // 新增纯固态
      solidSection: [],
      // 新增事件相机
      cameraSection: [],
      induList: [],
      soluList: [],
      footInfo: [],
      matrixList: [],
    };
  },
  methods: {
    bindAside() {
      this.asideNav = !this.asideNav;
      this.langShow = false;
      this.footLang = false;
    },
    changeLang() {
      this.langShow = !this.langShow;
    },
    selectLang(num) {
      this.langIndex = num;
      this.langShow = false;
      this.footLang = false;
      if (num == 0) {
        // 跳转中文
        console.log(this.$route.path);
        if (this.$route.path.split("en")[1].length !== 0) {
          window.location.href = this.$route.path.split("en")[1];
        } else {
          this.$router.push("/");
        }
        // this.$router.push("/");
      }
    },
    bindLang() {
      this.footLang = !this.footLang;
    },
    // 页面滚动事件
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 0) {
        this.headActive = true;
      } else {
        this.headActive = false;
      }
    },
    headEnter() {
      this.headActive = true;
    },
    headOuter() {
      this.headActive = false;
    },
    // 鼠标移入产品中心
    handleEnter() {
      this.procenter = true;
    },
    // 鼠标移出产品中心
    handleLeave() {
      this.procenter = false;
    },
    // 鼠标移入行业应用
    industryShow() {
      this.industryBox = true;
    },
    // 鼠标移出行业应用
    industryHide() {
      this.industryBox = false;
    },
    // 鼠标移入解决方案
    solutionEnter() {
      this.solcenter = true;
    },
    // 鼠标移出解决方案
    solutionLeave() {
      this.solcenter = false;
    },
    // 鼠标移入关于我们
    aboutShow() {
      this.aboutBox = true;
    },
    // 鼠标移出关于我们
    aboutHide() {
      this.aboutBox = false;
    },
    bindHome() {
      this.$router.push("/en");
      this.asideNav = false;
      this.induMore = false;
      this.aboutMore = false;
      this.soluMore = false;
      this.proMore = false;
    },
    bindPage(val) {
      this.$router.push(`/en/${val}`);
      this.asideNav = false;
      this.induMore = false;
      this.aboutMore = false;
      this.soluMore = false;
      this.proMore = false;
    },
    navigateToPage(num, val) {
      if (num == 1) {
        this.$router.push(`/en/product/${val}`);
      } else if (num == 2) {
        this.$router.push(`/en/multiline/${val}`);
      } else if (num == 3) {
        this.$router.push(`/en/industry/${val}`);
      } else if (num == 4) {
        this.$router.push(`/en/solution/${val}`);
      } else if (num == 5) {
        this.$router.push(`/en/module/${val}`);
      } else if (num == 6) {
        this.$router.push(`/en/solid/${val}`);
      } else if (num == 7) {
        this.$router.push(`/en/camera/${val}`);
      }

      this.asideNav = false;
      this.induMore = false;
      this.aboutMore = false;
      this.soluMore = false;
      this.proMore = false;
    },
    bindMore(val) {
      if (val == 3) {
        this.aboutMore = !this.aboutMore;
      } else if (val == 2) {
        this.soluMore = !this.soluMore;
      } else if (val == 1) {
        this.induMore = !this.induMore;
      } else {
        this.proMore = !this.proMore;
      }
    },
    asideScroll(e) {
      e.stopPropagation();
    },
    // 查询产品中心单线列表
    async selectProbar() {
      let that = this;
      if (that.eindexDanList.length == 0) {
        let resOne = await seleData(
          "danproducts",
          { lydz: true, img: true, new: true, name: true },
          60
        );
        // let xltArray = resOne.map((item) => {
        //   return item.img;
        // });
        // xltArray = await that.getFileURL(xltArray);
        // that.proSection = resOne.map((item, index) => {
        //   item.img = xltArray[index];
        //   return item;
        // });
        that.proSection = resOne
        //   console.log(that.proSection);
        that.$store.commit("saveEindexDanList", that.proSection);
      } else {
        that.proSection = that.eindexDanList;
      }
    },
    // 查询产品中心多线列表
    async selectDprobar() {
      let that = this;
      if (that.eindexDuoList.length == 0) {
        let resTwo = await seleData(
          "duoproducts",
          { lydz: true, img: true, new: true, name: true },
          60
        );
        // let xltArray = resTwo.map((item) => {
        //   return item.img;
        // });
        // xltArray = await that.getFileURL(xltArray);
        // that.dproSection = resTwo.map((item, index) => {
        //   item.img = xltArray[index];
        //   return item;
        // });
        that.dproSection = resTwo
        that.$store.commit("saveEindexDuoList", that.dproSection);
      } else {
        that.dproSection = that.eindexDuoList;
      }
    },
    // 查询产品中心module列表
    async getModuleList() {
      let that = this;
      if (that.eindexModule.length == 0) {
        await seleData(
          "module",
          { lydz: true, cpt: true, new: true, cpmc: true },
          60
        ).then((res) => {
          // res.forEach(async (item, index) => {
          //   await this.getFileURL([item.cpt]).then((res2) => [
          //     that.$set(res, index, { ...res[index], cpt: res2[0] }),
          //   ]);
          // });
          that.moduleSetion = res;
          that.$store.commit("saveEindexModule", res);
        });
      } else {
        that.moduleSetion = that.eindexModule;
      }
    },
    // 查询产品中心纯固态雷达
    async getSolidList() {
      let that = this;
      if (that.eindexSolid.length == 0) {
        await seleData(
          "solidState",
          { lydz: true, img: true, new: true, e_name: true },
          60
        ).then((res) => {
          // res.forEach(async (item, index) => {
          //   await this.getFileURL([item.img]).then((res2) => [
          //     that.$set(res, index, { ...res[index], img: res2[0] }),
          //   ]);
          // });
          that.solidSection = res;
          that.$store.commit("saveEindexSolid", res);
        });
      } else {
        that.solidSection = that.eindexSolid;
      }
    },
    // 查询产品中心事件相机
    async getCameraList() {
      let that = this;
      if (that.eindexCamera.length == 0) {
        await seleData(
          "camera",
          { lydz: true, img: true, new: true, name: true },
          60
        ).then((res) => {
          that.cameraSection = res;
          that.$store.commit("saveEindexCamera", res);
        });
      } else {
        that.cameraSection = that.eindexCamera;
      }
    },
    // 行业应用下拉列表
    async selectIndu() {
      let that = this;
      if (that.eindexInduList.length == 0) {
        that.induList = await seleData("industry-en", { lydz: true, name: true }, 100);
        that.$store.commit("saveEindexInduList", that.induList);
      } else {
        that.induList = that.eindexInduList;
      }
    },
    // 解决方案下拉列表
    async selectSolu() {
      let that = this;
      // that.soluList = await seleData("jjfa", { lydz: true, yjfl: true, name: true }, 60);
      if (that.eindexSoluList.length == 0) {
        let array = await seleData("jjfafl-en", { name: true }, 100);
        array.forEach(async (item) => {
          let res = await getSeleData(
            "jjfa-en",
            { lydz: true, yjfl: true, name: true },
            { yjfl: item._id },
            60
          );
          item.list = res;
        });
        that.soluList = array;
        that.$store.commit("saveEindexSoluList", array);
      } else {
        that.soluList = that.eindexSoluList;
      }
    },
    // 获取页尾信息
    async getFooterInfo() {
      let that = this;
      if (that.eindexFooter.length == 0) {
        let res = await getAllData("cominfo-en", 100);
        await that.getFileURL([res[0].wx]).then((res2) => {
          res[0].wx = res2[0];
        });
        that.footInfo = res;
        that.$store.commit("saveEindexFooter", res);
      } else {
        that.footInfo = that.eindexFooter;
      }
    },
    // 获取矩阵平台
    async getMatrixList() {
      let that = this;
      if (that.eindexMatrix.length == 0) {
        let res = await seleData("jzpt-en", { dbtb: true, link: true }, 100);
        let dbtbArray = res.map((item) => {
          return item.dbtb;
        });
        dbtbArray = await that.getFileURL(dbtbArray);
        that.matrixList = res.map((item, index) => {
          item.dbtb = dbtbArray[index];
          return item;
        });
        that.$store.commit("saveEindexMatrix", that.matrixList);
      } else {
        that.matrixList = that.eindexMatrix;
      }
    },
  },
  created() {
    // this.selectProbar();
    // this.selectDprobar();
    // this.selectIndu();
    // this.selectSolu();
    // this.getFooterInfo();
    // this.getMatrixList();
    this.runConcurrently([
      this.selectDprobar(),
      this.selectProbar(),
      this.getModuleList(),
      this.getSolidList(),
      this.getCameraList(),
      this.selectIndu(),
      this.selectSolu(),
      this.getFooterInfo(),
      this.getMatrixList(),
    ]);
  },
  mounted() {
    const script = document.getElementById("aifanfan-script");
    if (script) {
      document.body.removeChild(script);
      // 移除爱番番创建的 DOM 元素
      const aifanfanElements = document.getElementById("aff-im-root"); // 替换为实际的爱番番元素类名
      aifanfanElements.remove();
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1136px) {
  .app-header-nav {
    display: none !important;
  }

  .aside-nav {
    display: block !important;
  }

  .app-foot1 {
    display: none !important;
  }

  .app-foot2 {
    display: block !important;
  }
}

@media screen and (min-width: 1136px) {
  .app-small-nav {
    display: none !important;
  }
}

@media screen and (max-width: 960px) {
  .app-header {
    height: 60px;
    padding: 0 30px;
  }

  .app-small-nav {
    top: 50px;
  }

  .app-logo {
    width: 131px;
    height: 26px;

    .img {
      width: 131px;
      height: 26px;
    }
  }

  // .app-footer {

  // }
  .app-footer-box {
    padding: 20px 30px;
  }

  .app-small-nav {
    padding: 0.3125rem 1.875rem 2.5rem;
  }
}

@media screen and (min-width: 960px) {
  .app-header {
    height: 80px;
    padding: 0 90px;
  }

  .app-small-nav {
    top: 80px;
  }

  .app-logo {
    width: 157.2px;
    height: 31.2px;

    .img {
      width: 157.2px;
      height: 31.2px;
    }
  }

  // .app-footer {
  //     padding: 2.5rem 5.625rem;
  // }
  .app-footer-box {
    padding: 2.5rem 5.625rem;
  }

  .app-small-nav {
    padding: 0.3125rem 5.625rem 2.5rem;
  }
}

@media screen and (min-width: 750px) {
  .side-menu {
    .menu-li {
      width: 70px;
      .side-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .social-text {
    .text {
      font-size: 13px !important;
      margin-top: 5px;
    }
  }
  .social-a {
    width: 70px;
    height: 60px;
  }
  .social-custom {
    font-size: 20px;
  }
  .form-li {
    height: 200px;
    width: 70px;
    .form-text {
      font-size: 18px;
    }
  }

  .side-menu {
    .menu-li {
      &:nth-child(1) {
        &:hover {
          .element-contact-form {
            width: 440px;
          }
        }
      }
      &:nth-child(2) {
        height: 75px;
        &:hover {
          width: 240px;
          border-radius: 5px 0 0 5px;
        }
      }
      &:nth-child(3) {
        height: 75px;
        &:hover {
          width: 240px;
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }
  .element-contact-form {
    right: 70px;
  }
}
@media screen and (max-width: 750px) {
  .side-menu {
    .menu-li {
      width: 40px;
      .side-icon {
        width: 22px;
        height: 22px;
      }
    }
  }
  .side-menu {
    .menu-li {
      &:nth-child(1) {
        &:hover {
          .element-contact-form {
            width: 350px;
          }
        }
      }
      &:nth-child(2) {
        height: 120px;
      }
      &:nth-child(3) {
        height: 82px;
      }
    }
  }
  .social-text {
    // height: 100%;
    // width: 50px;
    // height: 100px;
    .text {
      writing-mode: vertical-rl;
    }
  }
  .social-a {
    width: 40px !important;
    // height: 100px;
  }
  .social-custom {
    font-size: 17px !important;
  }
  .form-li {
    // height: 180px;
    width: 40px;
    .form-text {
      font-size: 16px !important;
    }
  }
  .element-contact-form {
    right: 40px;
  }
}

@media screen and (max-width: 600px) {
  .app-header {
    height: 50px;
  }

  .app-logo {
    width: 110px;
    height: 22px;

    .img {
      width: 110px;
      height: 22px;
    }
  }
}
@media screen and (max-width: 450px) {
  .side-menu {
    .menu-li {
      &:nth-child(1) {
        &:hover {
          .element-contact-form {
            width: 280px;
          }
        }
      }
    }
  }
}

.nav-active {
  font-weight: 600;
  font-family: "Poppins-Medium", sans-serif;
}
.nav-active2 {
  color: #1d61e5 !important;
}

.activeOne {
  background-color: #fff;
  color: #000;

  .app-header-nav .item {
    color: #000;
  }

  .app-switch {
    color: #000;
    border: 1px solid #000;
  }
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease;

  &:hover {
    background-color: #fff;
    color: #000;

    .app-header-nav .item {
      color: #000;
    }

    .app-switch {
      color: #000;
      border: 1px solid #000;

      .black-down {
        display: block !important;
      }

      .white-down {
        display: none !important;
      }
    }

    .aside-nav .black-img {
      display: block !important;
    }

    .aside-nav .white-img {
      display: none !important;
    }
  }
}

.app-header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  transform: translateX(-6%);

  .item {
    margin: 0 1.25rem;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    border-bottom: none;
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-family: "Poppins-Regular", sans-serif;
    cursor: pointer;

    .bag {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .indu-box {
      position: relative;
    }

    .about-box {
      position: relative;
    }

    &:hover {
      font-family: "Poppins-Bold", sans-serif;
      color: #1d61e5 !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 40%;
      height: 0.1313rem;
      background-color: #1d61e5;
      left: 50%;
      bottom: 8px;
      translate: -50%;
      transition: transform 0.4s;
      transform: scaleX(0);
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
}

.industry-bag,
.about-bag {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 99;
  min-width: 130px;
  padding: 7px;

  .odd {
    display: block;
    color: #000;
    padding: 10px 20px;
    text-align: center;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    text-decoration: none;
    min-width: 200px;

    &:hover {
      // background-color: #f2f2f2;
      color: #1d61e5;
    }
  }
}

.app-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.aside-nav {
  margin-left: 1.875rem;
  width: 1.875rem;
  height: 1.875rem;
  display: none;

  .img {
    width: 1.875rem;
    height: 1.875rem;
  }

  .colse {
    width: 1.875rem;
    height: 1.875rem;
    scale: 0.8;
  }
}

.app-switch {
  position: relative;
  width: 65px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(254, 254, 255, 1);
  font-size: 0.875rem;
  border: 1px solid #fff;
  border-radius: 4px;
  font-family: "Poppins-Regular", sans-serif;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
    margin-left: 7px;
  }

  .dropdown {
    position: absolute;
    top: 26px;
    left: 0;
    width: 65px;
    text-align: center;
    background-color: #fff;
    color: #000;
    font-size: 0.875rem;
    font-family: "Poppins-Regular", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;

    span {
      display: block;
      width: 100%;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      cursor: pointer;
    }

    .active {
      background-color: #f2f2f2;
    }
  }
}

// 产品列表
@keyframes promove {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// 产品下拉列表
.app-protypes {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 20px 90px;
  animation: promove 0.3s ease;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .inner {
    // width: 100%;
    max-width: 1500px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .pro-cell {
    // width: 100%;
    margin-bottom: 20px;
    margin-right: 50px;

    & > p {
      width: 100%;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 18px;
      color: #010c21;
      margin-bottom: 20px;
    }

    .types {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 10px;
    }

    .item {
      position: relative;
      margin: 0 5px 8px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;

      img {
        // width: 136px;
        // height: 80px;
        width: 100px;
        height: auto;
        aspect-ratio: 113/88;
      }

      .name {
        display: block;
        font-family: "Poppins-Regular", sans-serif;
        font-size: 16px;
        color: #010c21;
        margin: 5px 0;

        &:hover {
          color: #1d61e5;
        }
      }

      .tag {
        position: absolute;
        top: 5px;
        right: 9px;
        display: block;
        width: 40px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        background: rgba(230, 15, 15, 1);
        border-radius: 5px 2px 5px 2px;
        font-family: "Poppins-Regular", sans-serif;
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
}

// 解决方案下拉列表
.app-resolve {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 20px 90px;
  animation: promove 0.3s ease;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .inner {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;

    .pack {
      color: #00041b;
      margin-right: 90px;
      margin-bottom: 25px;

      .primary {
        position: relative;
        font-family: "Poppins-Medium", sans-serif;
        font-size: 18px;
        margin-bottom: 10px;

        &::after {
          content: "";
          position: absolute;
          width: 146px;
          height: 1px;
          background-color: #b6b7b8;
          bottom: -10px;
          left: 0;
          z-index: 9;
        }
      }

      .second {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 16px;
        padding-top: 10px;

        .item {
          text-decoration: none;
          color: #010c21;
          display: block;
          padding: 3px 0;

          &:hover {
            color: #1d61e5;
          }
        }
      }
    }
  }
}

// footer
.app-footer-box {
  width: 100%;
  background: #010c21;
}

.app-footer {
  width: 100%;
  max-width: 1500px;
  color: #ffffff;
  transition: all 0.5s ease;
  margin: 0 auto;
}

.foot-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #454545;

  .mid {
    transform: translateX(-6%);

    .item {
      color: #fff;
      text-decoration: none;
      margin: 0.9375rem;
      font-size: 1rem;
      opacity: 0.8;
      font-family: "Poppins-Regular", sans-serif;
    }
  }

  .app-switch {
    color: rgba(254, 254, 255, 0.8);
  }
}

.app-business {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.875rem 0;
  font-family: "Poppins-Regular", sans-serif;

  .left {
    .cell {
      font-size: 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0.75rem 0;

      & > img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.625rem;
      }

      span {
        opacity: 0.8;
      }
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .link {
      display: block;

      & > img {
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 1.8125rem;
      }
    }

    .wechat {
      width: 10rem;
      height: 10rem;
    }
  }
}

// foot1
.app-foot1 {
  display: block;
}

// foot2
.app-foot2 {
  display: none;

  .top {
    width: 100%;
    display: flex;
    align-items: center;

    .lt {
      width: 100%;
      display: flex;
      align-items: center;

      span {
        color: #ffffff;
        font-size: 0.8125rem;
        opacity: 0.8;
      }

      .icon {
        width: 22px;
        height: 22px;
        margin-left: 13px;
      }
    }
  }

  .app-switch {
    opacity: 0.8;
  }

  .app-logo .img {
    margin: 20px 0;
  }

  .cell {
    font-size: 0.8125rem;
    margin-bottom: 8px;
    opacity: 0.8;
  }

  .cell-box {
    width: 100%;
    display: flex;
    align-items: center;

    .cell {
      margin-right: 14px;
    }

    .cell:nth-child(1) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        height: 13px;
        width: 1px;
        background-color: #fff;
      }
    }
  }
}

// asideNav
.app-small-nav {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 99;
  transition: all 0.5s ease;
  max-height: 100vh;
  overflow-y: scroll;

  .item {
    width: 100%;
    height: 2.7rem;
    line-height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.0625rem solid #f2f2f2;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    color: #010917;
    cursor: pointer;

    .img {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
      transition: rotate 0.3s ease;
    }
  }

  .active {
    rotate: 90deg;
  }
}

.app-container {
  margin-top: -5rem;
  min-height: 90vh;
}

.nav-more {
  width: 100%;
  background-color: #f2f2f2;
  border-bottom: 0.0625rem solid #f2f2f2;
  padding: 10px;

  span {
    display: block;
    color: #1d61e5;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    padding: 8px 0;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #1d61e5;
  }

  .first {
    padding: 10px 10px;
    p {
      color: #010c21;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 16px;
    }

    .second {
      padding: 5px 10px;

      span {
        padding: 5px 0 !important;
      }
    }
  }
}
.mailto {
  text-decoration: none;
  color: #ffffff;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
// .side-menu {
//   position: fixed;
//   top: 50%;
//   right: 0;
//   transform: translateY(-50%);
//   z-index: 999;
//   .item {
//     width: 110px;
//     height: 32px;
//     background-color: var(--main-one-color);
//     border-radius: 5px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 15px;
//     .side-eamil {
//       text-decoration: none;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       .side-icon {
//         width: 20px;
//         height: 20px;
//       }
//       .text {
//         color: #fff;
//         font-size: 13px;
//         margin-left: 10px;
//         padding-top: 5px;
//       }
//     }
//   }
// }

.side-menu {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .menu-li {
    transition: 0.5s ease;
    // width: 70px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:nth-child(1) {
      background-color: #3461dc;
      border-radius: 5px 0 0 0;
      &:hover {
        // width: 70px;
        .element-contact-form {
          // width: 440px;
        }
      }
    }
    &:nth-child(2) {
      background-color: #5ec16c;
      overflow: hidden;
      // height: 75px;
      // &:hover {
      //   width: 240px;
      //   border-radius: 5px 0 0 5px;
      // }
    }
    &:nth-child(3) {
      background-color: #1ad9d0;
      border-radius: 0 0 0 5px;
      overflow: hidden;
      // height: 75px;
      // &:hover {
      //   width: 240px;
      //   border-radius: 5px 0 0 5px;
      // }
    }
  }
}
.menu-li {
  a {
    text-decoration: none;
    color: #fff;
  }
  .side-icon {
    // width: 30px;
    // height: 30px;
  }
  .form-li {
    // height: 200px;
    // width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .form-text {
      // font-size: 18px;
      color: #fff;
      writing-mode: vertical-rl;
      margin-bottom: 10px;
    }
  }
  .social-custom {
    // height: 60px;
    // line-height: 60px;
    display: block;
    flex: 1;
    text-align: center;
    // font-size: 20px;
  }
  .social-text {
    display: block;
    .text {
      // font-size: 13px !important;
      // margin-top: 5px;
    }
  }
  .social-a {
    // width: 70px;
    // height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.element-contact-form {
  position: absolute;
  // right: 70px;
  top: -30%;
  width: 0px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  transition: 0.5s ease;
  .h3 {
    font-size: 18px;
    color: var(--main-one-color);
    font-family: "Poppins-Medium", sans-serif;
    margin: 10px 0 20px;
  }
}
</style>
